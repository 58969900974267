import React from 'react';
import { ProfileData } from "./ProfileData";
import { useIsAuthenticated } from "@azure/msal-react";
import Image from '../img/building_purple.png';
import "./Home.css";

export function Home (){
   /* static displayName = Home.name;*/
    const isAuthenticated = useIsAuthenticated();
    return (
        <>
            {isAuthenticated ?
            <div className="profile-data-wrapper">
            <ProfileData /> 
            </div>:
            <section className="header">
            <div className="content" style={{ backgroundImage: `url(${Image})` }}>
            <h1>Welcome to REINZ Sales Return Portal</h1>
            </div>
            </section>
            }
        </>
    );
  
}
