import { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { GetAccessToken } from "../index";
import { useState } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';
import DataTable from 'react-data-table-component';
import styled from "styled-components";

export function GetReturnsListNil() {
    const { instance } = useMsal();
    const [List, setList] = useState([]);
    const [globalFilterText, setGlobalFilterText] = useState("");
    const [tableLoadStatus, setTableLoadStatus] = useState(false);
    const columns = [
        //{
        //    name: "Status",
        //},
        //{
        //    name: "Errors",
        //},
        //{
        //    name: "Number",
        //    selector: row => row.streetNumber,
        //    sortable: true,
        //},
        //{
        //    name: "Street",
        //    selector: row => row.street,
        //    sortable: true,
        //},
        //{
        //    name: "Suburb",
        //    selector: row => row.suburb,
        //    sortable: true,
        //},
        //{
        //    name: "City",
        //    selector: row => row.townCity,
        //    sortable: true,
        //},
        {
            name: "Organisation",
            selector: row => row.organisationCode,
            sortable: true,
        },
        {
            name: "Property Type",
            selector: row => row.saleType,
            sortable: true,
        },
        //{
        //    name: "Property Category",
        //    selector: row => row.saleCategory,
        //    sortable: true,
        //},
        //{
        //    name: "Method of Sale",
        //    selector: row => row.saleMethod,
        //    sortable: true,
        //},
        //{
        //    name: "List Price",
        //    selector: row => row.listPrice,
        //    sortable: true,
        //},
        //{
        //    name: "Sale Price",
        //    selector: row => row.salePrice,
        //    sortable: true,
        //},
        //{
        //    name: "Milk Solids",
        //    selector: row => row.milkSolids,
        //    sortable: true,
        //},
        //{
        //    name: "Land Area",
        //    selector: row => row.landArea,
        //    sortable: true,
        //},
        //{
        //    name: "Sale Price/Ha",
        //    selector: row => row.salePrice,
        //    sortable: true,
        //},
        //{
        //    name: "Settlement Date",
        //    selector: row => row.settlementDate,
        //    sortable: true,
        //},
        {
            name: "Return Period",
            selector: row => row.salePeriod,
            sortable: true,
        },
        {
            name: "Load Date",
            selector: row => row.created.slice(0, 10),
            sortable: true,
        },

    ];
    const fetchNilReturnsList = async () => {
        //console.log("profile");
        const url = "api/ReturnListNil/GetNilReturnList";
        const tokenRequest = {
            //scopes: ["https://reinztestorg.onmicrosoft.com/api_access/api_access"],
            scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
            account: instance.getActiveAccount()
        };
        const accessToken = await GetAccessToken(tokenRequest);

        try {
            setTableLoadStatus(true);
            await fetch(url,
                {
                    method: "GET",
                    headers: new Headers(
                        {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }),
                })
                .then((response) => {
                    console.log(response, 'response');
                    if (response.status !== 200 && response.status !== 201) {
                        console.log("not ok" + response.status);
                    }
                    else {
                        return response.json()
                    }
                })
                .then(data => {
                    console.log(data)
                    if (data !== null && data !== undefined) {
                        setList(data)
                    }
                    /*console.log(List);*/
                    setTableLoadStatus(false);
                })
                //setIsLoading(false)
                .catch(error => {
                    console.error('Error:', error);
                    setTableLoadStatus(false);
                });
        }
        catch (error) {
            if (error.response) {
                setTableLoadStatus(false);
                console.log(error);
            }
        }
    }
    useEffect(() => {
       fetchNilReturnsList();
    }, []);

    //useEffect(() => {
    //    let timerId = setTimeout(() => {
    //        fetchNilReturnsList();
    //    }, 20000);
    //    return () => {
    //        clearTimeout(timerId)
    //    };

    //}, [List]);

    const handleGlobalFilter = (e) => {
        const value = e.target.value;
        setGlobalFilterText(value);
        fetchNilReturnsList();
    };

    const filteredData = List.filter((row) => {
        return Object.values(row).some((value) =>
            (value || "").toString().toLowerCase().includes(globalFilterText.toLowerCase()));

    });
    const tableCustomStyles = {
        headRow: {
            style: {
                color: '#fff',
                backgroundColor: '#331b8c'
            },
        }
    }

    return (
        <>
            <Placeholder xs={12} style={{ backgroundColor: "#331b8c" }} /><br /><br />
            <div class="row">
            <div class="col-sm-8">
            <input
                type="search"
                className="form-control-sm border ps-3"
                placeholder="Search"
                onChange={handleGlobalFilter}
                value={globalFilterText}
                    />
                </div></div><br/>                    
            <DataTable
                columns={columns}
                data={filteredData}
                dense
                pagination
                highlightOnHover
                responsive
                paginationPerPage={50}
                paginationRowsPerPageOptions={[50, 100, 150]}
                customStyles={tableCustomStyles}
                progressPending={tableLoadStatus}>
            </DataTable>
            
            
        </>
    );
}
/*<Placeholder xs={12} style={{ backgroundColor: "#331b8c" }} />*/