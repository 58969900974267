import React from "react";
import { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { GetAccessToken } from "../index";
import { useState } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';
import "./Home.css";
import Button from 'react-bootstrap/Button';
import { EditResidentialReturn } from "./EditResidentialReturn";
import { EditRuralReturn } from "./EditRuralReturn";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
//import { APIProvider, Map, MapCameraChangedEvent } from "@vis.gl/react-google-maps";
import { DateRangePicker } from 'rsuite';
import { CheckTreePicker, Stack } from 'rsuite';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import 'rsuite/DateRangePicker/styles/index.css';
import 'rsuite/CheckTreePicker/styles/index.css';

const predefinedDateRanges = [
    {
        label: 'Current Month',
        value: [startOfMonth(addMonths(new Date(), -1)), new Date()]
    },
    {
        label: 'Last Month',
        value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))]
    },
    {
        label: 'Last 3 Months',
        value: [startOfMonth(addMonths(new Date(), -3)), endOfMonth(addMonths(new Date(), -1))]
    }
    ,
    {
        label: 'Last 6 Months',
        value: [startOfMonth(addMonths(new Date(), -6)), endOfMonth(addMonths(new Date(), -1))]
    }
    ,
    {
        label: 'Last 12 Months',
        value: [startOfMonth(addMonths(new Date(), -12)), endOfMonth(addMonths(new Date(), -1))]
    }
];

const returnStatusData = [
    {
        label: 'Processed',
        value: 'Processed'
    },
    {
        label: 'Update',
        value: 'Update'
    },
    {
        label: 'Nil Return',
        value: 'Nil Return'
    },
    {
        label: 'Duplicate',
        value: 'Duplicate'
    },
    {
        label: 'Unresolved Address',
        value: 'Unresolved Address'
    },
    {
        label: 'Error',
        value: 'Error'
    }
];

const returnSaleMethodData = [
    {
        label: 'P - Private Treaty(Neg.)',
        value: 'P - Private Treaty(Neg.)'
    },
    {
        label: 'A - Auction',
        value: 'A - Auction'
    },
    {
        label: 'T - Tender',
        value: 'T - Tender'
    }
];

const categoryTypes = [
    {
        label: 'Residential',
        value: 'ResidentialAll',
        children: [
            {
                label: 'Residence',
                value: 'Re-Residence'
            },
            {
                label: 'Residential Section',
                value: 'Re-Residential_Section'
            },
            {
                label: 'Unit',
                value: 'Re-Unit'
            },
            {
                label: 'Apartment',
                value: 'Re-Apartment'
            },
            {
                label: 'Home and Income',
                value: 'Re-Home_and_Income'
            },
            {
                label: 'Residential Investment Block',
                value: 'Re-Residential_Investment_Block'
            },
            {
                label: 'Residential - Other',
                value: 'Re-Unknown'
            },
            {
                label: 'Lifestyle Blocks',
                value: 'Re-Lifestyle_Blocks'
            }
        ]
    },
    {
        label: 'Rural',
        value: 'RuralAll',
        children: [
            {
                label: 'Lifestyle Blocks',
                value: 'Ru-Lifesty_leBlocks'
            },
            {
                label: 'Rural - Other',
                value: 'Ru-Unknown'
            },
            {
                label: 'Apartment',
                value: 'Ru-Apartment'
            },
            {
                label: 'Horticulture',
                value: 'Ru-Horticulture'
            },
            {
                label: 'Dairy',
                value: 'Ru-Dairy'
            },
            {
                label: 'Dairy Support',
                value: 'Ru-Dairy_Support'
            },
            {
                label: 'Arable',
                value: 'Ru-Arable'
            },
            {
                label: 'Forestry',
                value: 'Ru-Forestry'
            },
            {
                label: 'Livestock',
                value: 'Ru-Livestock'
            },
            {
                label: 'Livestock - Finishing',
                value: 'Ru-Livestock_-_Finishing'
            },
            {
                label: 'Livestock - Grazing',
                value: 'Ru-Livestock_-_Grazing'
            },
            {
                label: 'Livestock - Breeding',
                value: 'Ru-Livestock_-_Breeding'
            },
            {
                label: 'Livestock - High Country',
                value: 'Ru-Livestock_-_High_Country'
            },
            {
                label: 'Livestock_-_Special',
                value: 'Ru-Livestock_-_Special'
            }
            
        ]
    }
];

export function GetReturnsListResidentialandRural() {
    const { instance } = useMsal();
    const [List, setList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const [globalFilterText, setGlobalFilterText] = useState("");
    const [showResidentialEdit, setshowResidentialEdit] = useState(false);
    const [showRuralEdit, setshowRuralEdit] = useState(false);
    const [saleId, setSaleId] = useState(0);
    const [data, setData] = useState(false);
    const [tableLoadStatus, setTableLoadStatus] = useState(false);
    const [SelectedDateRange, setDateRangeValue] = useState([]);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [SelectedCategory, setReturnListCategory] = useState([]);
    const [SelectedReturnStatus, setReturnListStatus] = useState([]);
    const [SelectedReturnSaleMethod, setReturnListSaleMethod] = useState([]);
    const [showChild, setShowChild] = useState(true);

    const columns = [
        //{
        //    name: "Status",
        //},
        //{
        //    name: "Errors",
        //},
        {
            name: "ReturnID",
            selector: row => row.datamineSaleId,
            sortable: true,
        },
        {
            name: "Number",
            selector: row => row.streetNumber,
            sortable: true,
            width: '100px',
        },
        {
            name: "Street",
            selector: row => row.street,
            sortable: true,
            width: '200px',
        },
        {
            name: "Suburb",
            selector: row => row.suburb,
            sortable: true,
            width: '150px',
        },
        {
            name: "City",
            selector: row => row.townCity,
            sortable: true,
            width: '150px',
        },
        {
            name: "Organisation",
            selector: row => row.organisationCode,
            sortable: true,
            width: '150px',

        },
        {
            name: "Property Type",
            selector: row => row.saleType,
            sortable: true,
            width:'150px'
        },
        {
            name: "Property Category",
            selector: row => row.saleCategory,
            sortable: true,
            width: '150px',
        },
        {
            name: "Method of Sale",
            selector: row => row.saleMethod,
            sortable: true,
            width: '200px',
        },
        {
            name: "List Price",
            selector: row => row.listPrice,
            sortable: true,
        },
        {
            name: "Sale Price",
            selector: row => row.salePrice,
            sortable: true,
        },
        {
            name: "Milk Solids",
            selector: row => row.milkSolids,
            sortable: true,
            width: '120px',
        },
        {
            name: "Land Area",
            selector: row => row.landArea,
            sortable: true,
            width: '150px',
        },
        //{
        //    name: "Sale Price/Ha",
        //    /*selector: row => row.salePrice,*/
        //    sortable: true,
        //    width: '150px',
        //},
        {
            name: "Settlement Date",
            selector: row => row.settlementDate,
            sortable: true,
            width: '150px',
        },
        {
            name: "Unconditional Date",
            selector: row => row.unconditionalDate.slice(0,10),
            sortable: true,
            width: '200px',
        },
        {
            name: "Load Date",
            selector: row => row.created.slice(0, 10),
            sortable: true,
            width: '150px',
        }
    ];
    

    const fetchReturnsList = async () => {

        //await sleep(2000); // Wait for 1 second

        const url = "api/ReturnList/GetReturnLists";
        const tokenRequest = {
            scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
            account: instance.getActiveAccount()
        };
        const accessToken = await GetAccessToken(tokenRequest);
        
        try {
            setTableLoadStatus(true);

            await fetch(url,
                {
                    method: "POST",
                    headers: new Headers(
                        {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`
                        }),
                    body: JSON.stringify({ fromDate: fromDate, toDate: toDate, SelectedCategory: SelectedCategory, SelectedReturnStatus: SelectedReturnStatus, SelectedReturnSaleMethod: SelectedReturnSaleMethod })
                })
                .then((response) => {
                    //console.log(response, 'response');
                    if (response.status !== 200 && response.status !== 201) {
                        console.log("not ok" + response.status);
                    }
                    else {
                        return response.json()
                    }
                })
                .then(data => {
                    //console.log(data)
                    if (data !== null && data !== undefined) {
                        setList(data);
                        setTableLoadStatus(false);
                    }
                    //console.log(List)
                    /*setTableLoadStatus(false);*/
                })
                //setIsLoading(false)
                .catch(error => {
                    console.error('Error:', error);
                    setTableLoadStatus(false);
                });
        }
        catch (error) {
            if (error.response) {
                setTableLoadStatus(false);
                console.log(error);
            }
        }
    }
    
    useEffect(() => {
        // the empty array in the param means we only run it once
        fetchReturnsList();
    }, []);
    
    //Submit delete request
    const sale = {
        saleId: saleId
    };
    const Submit = async (e) => {
        /*e.preventDefault();*/
        setShowModal(false);
        //alert("Hello1");

        const url = "api/ResidentialReturn/DeleteReturn/?id=" + saleId;
        const tokenRequest = {
            scopes: ["https://reinztestorg.onmicrosoft.com/app_token/api_access"],
            account: instance.getActiveAccount()
        };
        try {
            const accessToken = await GetAccessToken(tokenRequest);
            //fetch
            fetch(url,
                {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(sale),
                })
                .then((response) => {
                    //console.log(response, 'response');
                    if (response.status !== 200 && response.status !== 201) {
                        //console.log("not ok" + response.status);
                    }
                    else {
                        return response.json()
                    }
                }).then(data => {
                    setData(data)
                    //console.log(data)
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        catch (error) {
            if (error.response) {
                console.log(error);
            }
        }
    }

    const handleGlobalFilter = (e) => {
        const value = e.target.value;
        setGlobalFilterText(value);
        fetchReturnsList();
    };
    
    const handleDateRangeChange = (e) => {
        setDateRangeValue(e);
        setFromDate(startOfMonth(e[0]));
        setToDate(endOfMonth(e[1]));
    };

    const handleDateRangeClose = () => {
        fetchReturnsList();
    };

    const handleReturnCategoryChange = (e) => {
        setReturnListCategory(e);
    };
    const handleReturnCategoryClose = (e) => {
        fetchReturnsList();
    };
    
    const handleReturnSaleMethodChange = (e) => {
        setReturnListSaleMethod(e);
    };
    const handleReturnSaleMethodClose = (e) => {
        fetchReturnsList();
    };
    const handleDeleteSaleAlert = () => {
        alert("Sale deleted successfully");
        fetchReturnsList();
        setData(false);
    };

    //const searchReturn = () => {
    //    fetchReturnsList();
    //}

    
    const filteredData = List.filter((row) => {
        //return Object.values(row).some((value) =>
        //    (value||"").toString().toLowerCase().includes(globalFilterText.toLowerCase()));
        // Convert value to string, trim spaces, and normalize to lowercase
        return Object.values(row).some((value) => {
            // Convert value to string, trim spaces, and normalize to lowercase
            const stringValue = (value || "")
                .toString()
                .toLowerCase()
                .replace(/\s+/g, ' '); // Normalize spaces (replace multiple spaces with one)

            return stringValue.includes(globalFilterText.toLowerCase());
        });
    });

    const ExpandedComponent = ({ data }) => {
        const center = {
            lat: Number(data.latitude),//51.509865,
            lng: Number(data.longitude),//-0.118092,
        };
        return data &&
            <>
            <div id="content" style={{ fontSize: "15px", fontFamily: "serif", fontStyle: "normal" }}>
            <div id="left">
            <div style={{ fontSize: "large", fontStyle: "italic" }}><b>{data.fullAddress}</b></div>
            <div>Return ID: {data.datamineSaleId}</div><br />
            <div>Admin Notes:{data.adminNotes}</div>
            <div><b>Bedrooms:</b>{data.bedrooms}</div>
            <div><b>Bathrooms:</b>{data.bathrooms}</div>
            <div>CV: ${data.valuation}</div>
            </div>
                <div id="middle">
                    {data.saleType === "Residential" || data.saleType==="residential" ?
                        <div><Button style={{ backgroundColor: "#331b8c" }} size="sm" onClick={() => {
                            setSaleId(data.saleId); setshowResidentialEdit(true); setshowRuralEdit(false);setShowChild(true)
                        }}><i className="bi bi-pencil-square"></i>&nbsp;Edit</Button></div> :
                        <div><Button style={{ backgroundColor: "#331b8c" }} size="sm" onClick={() => {
                            setSaleId(data.saleId); setshowResidentialEdit(false); setshowRuralEdit(true);setShowChild(true)
                        }}><i className="bi bi-pencil-square"></i>&nbsp;Edit</Button></div>
                        }&nbsp;
                        <div><Button style={{ backgroundColor: "#331b8c" }} size="sm" onClick={() => {
                        setSaleId(data.saleId); setShowModal(true)
                        }}><i className="bi bi-trash3-fill"></i>&nbsp;Delete</Button></div>
                </div>
            <div id="right">
                    
                        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} onLoad={() => console.log(data.latitude, data.longitude)}>
                        <Map
                        style={{ width: '75vw', height: '25vh' }}
                        defaultCenter={center}
                        defaultZoom={13}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}>
                        <Marker position={center} />
                        </Map>
                        </APIProvider>
            </div>
            <hr/>
            </div>
            
            </>
    };

    const handleGoBack = () => {
        setShowChild(false); // Hide child component
        fetchReturnsList();
    };
    const filtersanddatatable = () => {
        return (
            <>
            <Placeholder xs={12} style={{ backgroundColor: "#331b8c" }} /><br /><br />
            <div class="row">
                <div class="col-sm-8">
                    <input
                        type="search"
                        className="form-control-sm border ps-3"
                        placeholder="Search"
                        onChange={handleGlobalFilter}
                        value={globalFilterText}
                        style={{ width: 380 }}
                    />
                </div>
            </div><br />
            <div class="row">
                <div class="col-sm-4">
                    <DateRangePicker
                            placeholder="Select Date Range"
                            format='MMM yyyy'
                            character=' - '
                            style={{ width: 380 }}
                            ranges={predefinedDateRanges}
                            value={SelectedDateRange}
                            onChange={handleDateRangeChange}
                            onExited={handleDateRangeClose}
                            cleanable={false}
                        />
                </div>

                <div class="col-sm-4">
                    <Stack spacing={10} direction="column" alignItems="flex-start">
                        <CheckTreePicker
                            searchable={false}
                            defaultExpandAll
                            data={categoryTypes}
                            style={{ width: 380 }}
                            onChange={handleReturnCategoryChange}
                            onClose={handleReturnCategoryClose}
                            placeholder="Categories" />
                    </Stack>
                </div>

                {/*<div class="col-sm-3">*/}
                {/*    <Stack spacing={10} direction="column" alignItems="flex-start">*/}
                {/*        <CheckTreePicker*/}
                {/*            searchable={false}*/}
                {/*            defaultExpandAll*/}
                {/*            data={returnStatusData}*/}
                {/*            style={{ width: 300 }}*/}
                {/*            onChange={handleReturnStatusChange}*/}
                {/*            onClose={handleReturnStatusClose}*/}
                {/*            placeholder="Return Status" />*/}
                {/*    </Stack>*/}
                {/*</div>*/}
                <div class="col-sm-4">
                    <Stack spacing={10} direction="column" alignItems="flex-start">
                        <CheckTreePicker
                            searchable={false}
                            defaultExpandAll
                            data={returnSaleMethodData}
                            style={{ width: 380 }}
                            onChange={handleReturnSaleMethodChange}
                            onClose={handleReturnSaleMethodClose}
                            placeholder="Sale Method" />
                    </Stack>
                </div>
            </div>
            {/*<div class="row">*/}
            {/*        <div class="col-sm-4">*/}
            {/*            <br />*/}
            {/*            <Button style={{ backgroundColor: "#f8f9fa", color: "#000" }} onClick={searchReturn}>*/}
            {/*                Search Return*/}
            {/*            </Button>*/}
            {/*        </div>*/}
            {/*</div>*/}
            <br />
            
            <DataTable
                columns={columns}
                data={filteredData}
                dense
                expandableRows
                expandableRowsComponent={ExpandedComponent}
                pagination
                highlightOnHover
                responsive
                paginationPerPage={50}
                paginationRowsPerPageOptions={[50, 100, 150]}
                customStyles={tableCustomStyles}
                progressPending={tableLoadStatus}
            />
                
        </>
                )

        
    }
    const tableCustomStyles = {
        headRow: {
        style: {
            color: '#fff',
            backgroundColor: '#331b8c',
            }
        }  
    
    }
    return (
        <>
            {data === true && handleDeleteSaleAlert()}
            <Modal size="lg" show={showModal} onHide={handleCloseModal} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>Delete Sale</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure, you want to mark this sale as delete?</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
                </Button>
                <Button style={{ backgroundColor: "#331b8c" }} onClick={Submit}>
                Delete
                </Button>
                </Modal.Footer>
            </Modal>
            {showResidentialEdit && showChild ? <EditResidentialReturn {...({ saleId, setSaleId })} onGoBack={handleGoBack} /> :
            <>
            {showRuralEdit && showChild ? <EditRuralReturn {...({ saleId, setSaleId })} onGoBack={handleGoBack} /> :
            filtersanddatatable()
            }
            </>
            }
        </>
    );
};

