import { AddRuralReturn } from './AddRuralReturn';
import { AddNilReturn } from './AddNilReturn'
import { AddResidentialReturn } from './AddResidentialReturn';
import { GetReturnsListNil } from './GetReturnsListNil';
import { GetReturnsListResidentialandRural } from './GetReturnsListResidentialandRural';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "./Home.css";
import Placeholder from 'react-bootstrap/Placeholder';

export function NavTabs(){
    return (
        <>
            <Tabs defaultActiveKey="returnslist" className="full-screen-tabs">
            <Tab eventKey="returnslist" title="Returns List- Rural and Residential" className="navlink">
            <GetReturnsListResidentialandRural />
            </Tab>
            <Tab eventKey="returnslistNil" title="Returns List- Nil" className="navlink">
            <GetReturnsListNil />
            </Tab>
            <Tab eventKey="residential" title="Add a Residential Return" className="navlink">
            <AddResidentialReturn />
            </Tab>
            <Tab eventKey="rural" title="Add a Rural Return" className="navlink">
            <AddRuralReturn />
            </Tab>
            <Tab eventKey="nil" title="Add a Nil Return" className="navlink" >
            <AddNilReturn />
            </Tab>
            </Tabs>
            <Placeholder xs={12} style={{ backgroundColor: "#331b8c" }} />
         </>
       
    );
}


